<script setup lang="ts">
/**
 * @author Ia Gugunava
 * @refactor Beka Chkhaidze
 */

import { computed, ref, watch } from "vue";
import { useCalendar, formatter } from "@/components/regularComponents/FormComponents/CustomCalendar/ts/calendar";
import { useBookingStore } from "@/store/booking";
import type { BaseTaxonomy } from "@/store/main/types/widgets-terms/terms";

import { useMainStore } from "@/store/main/index";

import Calendar from "@/components/regularComponents/FormComponents/CustomCalendar/Calendar.vue";
import TimesList from "@/components/smartComponents/ContactUs/parts/TimesList.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";

withDefaults(
  defineProps<{
    isCalendar?: boolean;
  }>(),
  {
    isCalendar: true,
  }
);
const mainStore = useMainStore();
const bookingStore = useBookingStore();

const emit = defineEmits(["clicked"]);
const isMob = computed(() => mainStore.windowWidth <= mainStore.breakpoints.mobile);

const newHeight = ref<number>();

const clickButton = (item: BaseTaxonomy) => {
  emit("clicked");
  bookingStore.time = item?.title;
};

const onHeightChange = (_newHeight: number) => {
  newHeight.value = _newHeight;
};

const { getBookedHours, bookedDays } = useCalendar();
const bookedHours = ref<string[]>();

watch(
  () => bookingStore.date,
  (n) => {
    bookedHours.value = getBookedHours(formatter(new Date(n)));
  }
);

watch(bookedDays, () => {
  bookedHours.value = getBookedHours();
});
</script>

<template>
  <div class="calendar-modal">
    <div class="calendar-modal__header">
      <h2>{{ $t("select meeting time") }}</h2>
      <p>{{ $t("you are on a video call with a dome consultant") }}</p>
    </div>
    <div class="calendar-modal__content d-flex" :style="`--max-h: ${newHeight}px;`">
      <div class="calendar-modal__content--calendar" v-if="isCalendar">
        <Calendar
          direction="top"
          v-model="bookingStore.date"
          v-model:timezone="bookingStore.timezone"
          @height-changed="onHeightChange"
        />
      </div>
      <div class="calendar-modal__content--hours" v-if="!isMob">
        <TimesList
          @select="clickButton"
          v-bind="{ calendarModal: bookingStore.date, bookedHours, useBorders: true }"
        />
      </div>

      <Button v-if="isMob" btnText="Confirm" @click="clickButton" class="mt-24" full />
    </div>
  </div>
</template>

<style lang="scss">
.calendar-modal {
  &__header {
    h2 {
      font-size: 36px;
      @include maxq(desktop-2k) {
        font-size: 26px;
      }
      @include maxq(desktop-lg) {
        font-size: 22px;
      }
    }

    p {
      font-size: 30px;

      @include maxq(desktop-2k) {
        font-size: 24px;
      }
      @include maxq(desktop-lg) {
        font-size: 16px;
      }
    }

    margin-bottom: 36px;
    @include maxq(desktop-lg) {
      margin-bottom: 24px;
    }

    @include maxq(mobile) {
      display: none;
    }
  }
  &__content {
    flex-wrap: wrap;
    .custom-calendar {
      border: 1px solid $silver;
      border-right: 0px;

      @include maxq(mobile) {
        border: unset;
      }
    }
    &--calendar {
      flex-basis: 68%;
      @include maxq(mobile) {
        width: 100%;
        flex-basis: unset;
      }
    }
    &__content {
      flex-wrap: wrap;
      .custom-calendar {
        border: 1px solid $silver;
        border-right: 0px;
      }
    }

    &--hours {
      flex-basis: 31.5%;
      .custom-select {
        .custom-input__content {
          border-right: 1px solid $silver;
        }
      }
    }
  }
}
</style>
